@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;500&family=Raleway:wght@100;300;400;500;600;700&display=swap');

h1 {
    font-size: 24px;
}

h3 {
    font-size: 16px;
    margin-top: 2rem;
}

.MainBarContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}