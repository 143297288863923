.ContactCard {
    width: 150px;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
}

.ContactImage {
    width: 150px;
    height: 150px;
    background-color: lightgray;
    border-radius: 50%;
}

.ContactImage img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 50%;
    transform: translate(-8%, 8%);
}

.ContactText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ContactText h3 {
    font-size: 16px;
    font-weight: 500;
}

.ContactText p {
    color: rgba(81, 81, 81, 1);
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;


}

.ContactEdit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContactEdit a {
    padding: 0.5rem;
}