.brochure , .sponsorType{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap ;
}
.brochure{
    justify-content: space-between;
    margin-bottom: 40px;
}
.sponsorType{
    list-style: none;
}
.sponsorType li{ 
    padding:10px 15px;
    background-color: #EFFFEB;
    margin:10px 15px;
    border-radius: 2px;
}
.sponsorHead{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.addButton{
    background-color: #17BEE8;
    color:white;
    padding:4px 25px;
    border-radius: 2px;
    border:none;
    outline:none;
}