.ImageCard {
    width: 309px;
    height: 239px;
    position: relative;
    margin: 1rem;
}

.ImageCard img {
    height: 100%;
    width: 100%;
    object-fit: fill;
    border-radius: 5px;
}

.DeleteButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.5);
}

.DeleteButton img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.GalleryContainer {
    width: 100%;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}