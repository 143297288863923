@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;500&family=Raleway:wght@100;300;400;500;600;700&display=swap);
.navbar {
    width: 95vw;
    height: 15vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
}

.navbar img {
    width: 50px;
}

.navbar div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar h1 {
    margin-left: 1rem;
}

.navbar p {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: rgba(81, 81, 81, 1);
}
.side-bar {
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin-left: 1rem;
    height: 70vh;
    border-right: 1px solid rgba(202, 202, 202, 1);
    float: left;
}

.side-bar ul {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.side-bar a {
    width: 215px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 1rem;
    font-family: 'poppins', sans-serif;
    text-decoration: none;
    color: rgba(121, 121, 121, 1);
}

.side-bar li:hover {
    background-color: lightgray;
    cursor: pointer;
}
h1 {
    font-size: 24px;
}

h3 {
    font-size: 16px;
    margin-top: 2rem;
}

.MainBarContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
}
.ImageCard {
    width: 309px;
    height: 239px;
    position: relative;
    margin: 1rem;
}

.ImageCard img {
    height: 100%;
    width: 100%;
    object-fit: fill;
    border-radius: 5px;
}

.DeleteButton {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0.5);
}

.DeleteButton img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.GalleryContainer {
    width: 100%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.EventCard {
    height: 250px;
    width: 520px;
    left: 381px;
    top: 225px;
    border-radius: 5px;
    border: 1px solid rgba(133, 133, 133, 1);
    margin: 1rem;
}

.EventImage {
    height: 174px;
    width: 225px;
    left: 404px;
    top: 242px;
    border-radius: 5px;
    background-color: lightblue;
    float: left;
    margin-top: 1.2rem;
    margin-left: 1.2rem;
}

.EventImage img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 5px;
}

.EventContent {
    width: 50%;
    padding: 1rem;
    float: right;
    margin-top: .5rem;
}

.EventContent p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
}

.EditDelete {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.EditDelete a {
    border: none;
    background: none;
    padding: .5rem;
    margin: .5rem;
}
.form-check {
    padding: 1rem;
    margin: 1rem;
}
.event {
    width: 300px;
    height: 350px;
    border: 2px solid rgba(255, 86, 35, 1);
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 2rem;
}


.event h3 {
    font-size: 26px;
    position: relative;
}

.event h3::before {
    content: '';
    position: absolute;
    top: -1rem;
    width: 50px;
    height: 5px;
    border-radius: 10px;
    background: rgba(255, 86, 35, 1);
}

.event span {
    font-size: 16px;
    color: rgba(255, 86, 35, 1);
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.event span img {
    margin-right: .5rem;
}

.event p {
    margin-top: 2rem;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
    text-align: left;
}

.edit-delete {
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.edit-delete a {
    border: none;
    background: none;
    padding: .5rem;
    margin: .5rem;
}

.yellow {
    border: 2px solid rgba(255, 151, 1, 1);
}

.yellow h3::before {
    background: rgba(255, 151, 1, 1);
}

.yellow span {
    color: rgba(255, 151, 1, 1);
}
.main-body {
    width: 70%;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    margin-right: 1rem;
    float: right;
    padding: 1rem;
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10rem;
}
.bg-image {
    width: 662px;
    height: 344px;
    background-color: lightgray;
}
.ContactCard {
    width: 150px;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 1rem;
}

.ContactImage {
    width: 150px;
    height: 150px;
    background-color: lightgray;
    border-radius: 50%;
}

.ContactImage img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 50%;
    transform: translate(-8%, 8%);
}

.ContactText {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.ContactText h3 {
    font-size: 16px;
    font-weight: 500;
}

.ContactText p {
    color: rgba(81, 81, 81, 1);
    font-family: Open Sans;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;


}

.ContactEdit {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ContactEdit a {
    padding: 0.5rem;
}
.EventCard {
    height: 250px;
    width: 520px;
    left: 381px;
    top: 225px;
    border-radius: 5px;
    border: 1px solid rgba(133, 133, 133, 1);
    margin: 1rem;
}

.EventImage {
    height: 174px;
    width: 225px;
    left: 404px;
    top: 242px;
    border-radius: 5px;
    background-color: lightblue;
    float: left;
    margin-top: 1.2rem;
    margin-left: 1.2rem;
}

.EventContent {
    width: 50%;
    padding: 1rem;
    float: right;
    margin-top: .5rem;
}

.EventContent p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
}

.EditDelete {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.EditDelete a {
    border: none;
    background: none;
    padding: .5rem;
    margin: .5rem;
}
.brochure , .sponsorType{
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap:wrap ;
}
.brochure{
    justify-content: space-between;
    margin-bottom: 40px;
}
.sponsorType{
    list-style: none;
}
.sponsorType li{ 
    padding:10px 15px;
    background-color: #EFFFEB;
    margin:10px 15px;
    border-radius: 2px;
}
.sponsorHead{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.addButton{
    background-color: #17BEE8;
    color:white;
    padding:4px 25px;
    border-radius: 2px;
    border:none;
    outline:none;
}
