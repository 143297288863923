.EventCard {
    height: 250px;
    width: 520px;
    left: 381px;
    top: 225px;
    border-radius: 5px;
    border: 1px solid rgba(133, 133, 133, 1);
    margin: 1rem;
}

.EventImage {
    height: 174px;
    width: 225px;
    left: 404px;
    top: 242px;
    border-radius: 5px;
    background-color: lightblue;
    float: left;
    margin-top: 1.2rem;
    margin-left: 1.2rem;
}

.EventImage img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 5px;
}

.EventContent {
    width: 50%;
    padding: 1rem;
    float: right;
    margin-top: .5rem;
}

.EventContent p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.01em;
    text-align: left;
}

.EditDelete {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.EditDelete a {
    border: none;
    background: none;
    padding: .5rem;
    margin: .5rem;
}